import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Navbar = ({ navDark }) => {
  // eslint-disable-next-line no-unused-vars
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <>
      <header
        className={`main-header ${navDark ? "position-absolute " : ""} w-100 `}
      >
        <nav
          className={`navbar navbar-expand-xl z-10 navbar-dar sticky-header affix`}
        >
          <div className="container d-flex align-items-center justify-content-lg-between position-relative">
            <Link
              to="/"
              className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none"
            >
              <img
                src="/assets/img/vera/logo-white.png"
                alt="logo"
                className="img-fluid logo-white"
              />
            </Link>
                      </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
