import React from "react";
import Navbar from "../../layout/Header/Navbar";
import Layout from "../../layout/Layout";
import LatestBlog from "../../components/blog/LatestBlog";

const VeraIndex = () => {
  return (
    <Layout classOpt="game-bg-dark">
      <Navbar navDark/>
      <LatestBlog />
    </Layout>
  );
};

export default VeraIndex;
