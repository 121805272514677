import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFoundScreen from "../components/others/NotFoundScreen";
import ScrollToTop from "../components/common/ScrollToTop";

import Home from "../pages/Home";
import Blog1 from "../pages/blog/Blog1";
import Blog2 from "../pages/blog/Blog2";

function index() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/1" element={<Blog1 />} />
        <Route exact path="/u/2" element={<Blog2 />} />
        <Route exact path="/404" element={<NotFoundScreen />} />
        <Route path="*" element={<NotFoundScreen />} />
      </Routes>
    </BrowserRouter>
  );
}

export default index;
