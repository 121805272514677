// app/src/pages/Home.js    
import React from 'react';

import VeraIndex from '../themes/vera';

const Home = () => {
  return (
      <VeraIndex />
  );
};

export default Home;
