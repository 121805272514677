import React from 'react';
import BlogDetails from '../../components/blog/BlogDetails';
import PageMeta from '../../components/common/PageMeta';
import PageHeader from '../../components/common/PageHeader';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

const Blog1 = () => {
  return (
    <Layout>
      <PageMeta title="VERA - Engineering Update - Post 1" />
      <Navbar navDark />
      <PageHeader title="VERA - Engineering Update - Post 1" />
      <BlogDetails />
    </Layout>
  );
};

export default Blog1;
