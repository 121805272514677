import React from 'react';
import { Link } from 'react-router-dom';

const ProfileCard = () => {
  return (
    <>
      <div className='author-wrap text-center bg-light p-5 sticky-sidebar rounded-custom mt-5 mt-lg-0'>
        <div class='team-member'>
          <img
            src='/assets/img/team/wes-1.png'
            alt='author'
            width='120'
            className='img-fluid shadow-sm rounded-circle'
          />
          <div className='author-info my-4'>
            <h5 className='mb-0'>Wes Lorenzini</h5>
            <span className='small'>Ex-Senior Software Lead<br />VERA</span>
            <ul className='list-unstyled author-social-list list-inline mt-3 mb-0'>
              <li className='list-inline-item ico-email'>
                <Link to='mailto:wes@wesworld.io' target='_blank'>
                  <i className='fa fa-envelope'></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileCard;
