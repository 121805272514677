import React from 'react';

const PageHeader = ({ title, desc, integration, blogtags }) => {
  return (
    <>
        <section
          className='page-header position-relative bg-dark pt-80'
          style={{
            backgroundColor: 'black',
          }}
        />
    </>
  );
};

export default PageHeader;
