import React from "react";
import { Link } from "react-router-dom";

const NotFoundScreen = () => {
  return (
    <>
      <section
        className="error-section ptb-120 bg-dark min-vh-100 w-100 d-flex flex-column justify-content-center"
        style={{
          background:
            "url('/assets/img/vera/bg-1.jpg)no-repeat bottom right",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-5 col-md-6">
              <div className="error-page-content-wrap">
                <h2 className="error-404 text-warning">404</h2>
                <h1 className="display-5 fw-bold">Oops! Page Not Found</h1>
                <p className="lead">
                  In the world of VERA, where XR dreams are spun,<br />
                  This page is missing, but the journey's just begun.<br />
                  Explore new paths and let your curiosity soar,<br />
                  With VERA's vibrant research, there's always more to explore.{" "}
                </p>

                <Link to="/" className="btn btn-primary mt-4">
                  Back to VERA Blog
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFoundScreen;
