import React from 'react';
import ProfileCard from './ProfileCard';

const BlogDetails = () => {
  return (
    <>
      <section className='blog-details ptb-60'>
        <div className='container'>
          <div className='row justify-content-between'>
            <div className='col-lg-8 pe-5'>
              <div className='blog-details-wrap'>
                <h1>VERA Engineering Update - Post 1</h1>
                <br />
                <p><h2>Welcome!</h2> This is the first VERA Engineering Blog post. I have done my best to cater this post to the audience at large, knowing that some of you have computer science expertise and some of you have expertise in other areas. If you have questions about something or need more explanation about anything in any way, please reach out and we will get back to you. We will update the blog with the questions and answers to keep a consistent workflow of progress, feedback, and iterations throughout.</p>
                <p>The information provided in this blog is not meant to be declarative. Additionally, in the spirit of making progress towards something tangible for the VERA leads (and committees where you deem appropriate) to review, you’ll notice that my primary focus has been on getting the “bones” of VERA snapped into place, with many processes’ details not yet teased apart.</p>
                <p>To support agile, iterative development, and to achieve my goal of having something exciting, presentable, and testable for the ISMAR tutorial in late October for researchers (tutorial attendees), my focus will be on development of a functional and viable end to end solution.</p>
                <br />
                <br />

                <h2>Current Goals for the First Prototype and Supporting Documentation Due in October</h2>
                <br />
                <h3>Must Have</h3>
                <ul className='content-list list-unstyled'>
                  <li>User registration and login</li>
                  <li>View all my studies</li>
                  <li>Define all possible states of a study (created, pending, ready, running, paused, completed, terminated, other?)</li>
                  <li>Create a study</li>
                  <li>Upload experiment files for study</li>
                  <li>View individual study page details</li>
                  <li>Retrieve study to headset (for test participant use)</li>
                  <li>Complete study tasks with data recording</li>
                  <li>Define initial data types and metrics</li>
                  <li>Send data from study participation back to VERA</li>
                  <li>Retrieve, format, and view study data</li>
                  <li>Institutional agreement draft finalized for UCF approval</li>
                  <li>Testing with test participants (grad students, volunteer committee members, etc.)</li>
                  <li>Establish recruitment strategies/plan and outreach methods to recruit people for the real participant pool, especially diverse populations, communities (Valerie, Tabitha, Dylan)</li>
                  <li>Establish initial draft of equitable sampling strategies for study recruitment (Rui, Valerie, Tabitha, Dylan)</li>
                  <li>Initial IRB management plan (Jonathan, Nicholas, Valerie, Ali)</li>
                  <li>Researcher creed (Jonathan)</li>
                </ul>
                <br />
                <h3>Nice to Have</h3>
                <ul className='content-list list-unstyled'>
                  <li>Share my study with other researcher users</li>
                  <li>Define researcher roles, affiliations, permissions</li>
                  <li>Define study-centric and researcher-centric metrics and KPIs for VERA’s and NSF’s use (new users by type over time, time stamps of creating, editing, pausing, terminating studies, % of shared studies, internal/external collaborations, measurements of success (recruitment, data quality, etc) minutes in VERA, minutes per page, minutes per study, etc)</li>
                  <li>Incorporate Questionnaire Templates (such as SUS, UEQ, TLX) that researchers can “add to study” to be completed in world during experiments</li>
                  <li>Participant UIs</li>
                  <li>Begin real participant outreach and onboarding</li>
                  <li>Establish IRB workflows including IRB kit</li>
                  <li>Define potential pain points in initial user journeys for researchers and participants where FAQs/help videos can assist/make an impact</li>
                </ul>
                <br />
                <h3>Out of Scope for Oct</h3>
                <ul className='content-list list-unstyled'>
                  <li>Non-minimal risk experiments</li>
                  <li>Synchronous multi-participant experiments</li>
                  <li>Vulnerable populations</li>
                  <li>Experiment compensation/payment</li>
                  <li>Secondary data use (can develop prompts for feedback on this prior to the tutorial)</li>
                </ul>
                <br />
                <br />
                <h3>User Registration/Login/Access</h3>
                <p>Registration for now is simple. You enter your email, receive an email with your invite code, create a username and select your Country.</p>
                <div className='media'>
                  <a href='/assets/img/vera/blog/1/1-register-1.gif' target='_blank' rel='noreferrer'>
                    <img src='/assets/img/vera/blog/1/1-register-1.gif' alt='VERA Registration' className='img-fluid' />
                  </a>
                  <a href='/assets/img/vera/blog/1/2-email-from-register-1.gif' target='_blank' rel='noreferrer'>
                    <img src='/assets/img/vera/blog/1/2-email-from-register-1.gif' alt='VERA Registration Email' className='img-fluid' />
                  </a>
                  <a href='/assets/img/vera/blog/1/3-onboarding1-1.gif' target='_blank' rel='noreferrer'>
                  <img src='/assets/img/vera/blog/1/3-onboarding1-1.gif' alt='VERA Onboarding Process' className='img-fluid' />
                  </a>
                </div>
                <p>We need to discuss what information will be required for the researcher registration beyond institutional (and departmental? And lab?) affiliation in future.</p>
                <p>What is the policy for when a researcher accepts a job at another institution? We may want to incorporate into the institutional agreement provisions.</p>
                <p>We should also discuss limiting researcher registration to those who have a .edu or similar address. This will also help with streamlining cross-checking with the institutional agreements to determine who has institutional permission to launch real studies with real participants.</p>
                <p>My thinking for now is that we do not want to lock researchers out or prevent them from exploring VERA prior to having the institutional agreement in place. They may want to test VERA out before going to the effort of navigating the institutional agreement to see if it’s worth it to them.</p>
                <p>However, researchers will not be able to launch a study with real participant pool without this institutional agreement in place with their institution.</p>
                <p>We are creating a draft of a VERA institutional agreement, similar to LookIt’s (and some academic-adjacent industry companies’) approach. If a researcher at a university is going to use VERA to conduct studies/collect data, their university’s authorized person (VPR office, IRB, Provost, or Chief Data Officer, etc.) must sign off. The person authorized to grant this permission varies role-wise institution to institution, and the first researcher from a university to use VERA must determine who this person is. Additional researchers from that university can be added via amendment, a simpler undertaking.</p>
                <p>Additionally, researcher students must be affiliated with a lab or advisor/faculty to run studies, rather than potentially going rogue and conducting studies without oversight of the person(s) who would have oversight in an in-person lab based study setting. This is a “nice to have” to incorporate in the UI for ISMAR but something to discuss soon from an implementation standpoint.</p>

                <h2>Create a Study Workflow</h2>
                <div className='media'>
                  <a href='/assets/img/vera/blog/1/4-create-view-update-study-1.gif' target='_blank' rel='noreferrer'>
                    <img src='/assets/img/vera/blog/1/4-create-view-update-study-1.gif' alt='VERA Create Update & Study Process' className='img-fluid' />
                  </a>
                </div>
                <p>This is a walkthrough of the early concept of the researcher study dashboard. Researchers can create a new study here and also see their full list of studies with high level details at a glance.</p>
                <p>Each study may be selected individually to see further details about that study, including unique identifier, date created, and date modified.</p>
                <p>In future iterations, we will incorporate the affordance to pause data collection on a currently running study (MUST HAVE FOR OCT), and also provide mechanisms for sharing the study internally (with other members of the researcher’s lab and institution) and externally (with researchers at other institutions) (NICE TO HAVE FOR OCT).</p>

                <h2>Unity Plugin / Package</h2>
                <div className='media'>
                  <a href='/assets/img/vera/blog/1/6-unity-vera-1.png' target='_blank' rel='noreferrer'>
                    <img src='/assets/img/vera/blog/1/6-unity-vera-1.png' alt='VERA Code in Unity Project' className='max-w-sm' />
                  </a>
                </div>
                <p>The current VERA Unity Package / Plugin contains:</p>
                <ul className='content-list list-unstyled'>
                  <li>HUD - VERA_UI_HUD.cs - Used to display information being sent and received from the Unity Study and VERA Backend.</li>
                  <li>API - VERA_API.cs - Used to connect to VERA Backend / API calls (Assets, Study Data, User Login)</li>
                  <li>Load Study Script - VERA_UI_LoadStudy.cs (Work in Progress) - Used as testing ground for downloading and loading study assets</li>
                  <li>Questionnaire Loader - VERA_UI_Panel_Coach_1 (Work in Progress) - Ideally to be used as a drop in UI Element so Participants can respond to Questionnaires</li>
                  <li>Researchers will be able to choose action / event to prompt the questions</li>
                </ul>
                <div className='media'>
                  <a href='/assets/img/vera/blog/1/5-update-study-status-with-unity-game-display-1.gif' target='_blank' rel='noreferrer'>
                    <img src='/assets/img/vera/blog/1/5-update-study-status-with-unity-game-display-1.gif' alt='VERA Study Update in Unity and Website' className='img-fluid' />
                  </a>
                </div>
                <p>In the demo above, you see the status of study change on the VERA Researcher Website which then loads / unloads the running study data into Unity. Currently the data is the description of Study while we continue to nail down asset delivery.</p>
                <br />
                <br />
                <br />
                <h2>Next Up</h2>
                <ul className='content-list list-unstyled'>
                  <li>A discussion about security</li>
                  <li>More progress on "Must Have" list above</li>
                </ul>
                <br />
                <br />
                <br />
                <p>Thank you for your time.</p>
                <p>Please reach out with any questions and we will ensure to address them.</p>
              </div>
            </div>
            <div className='col-lg-4'>
              <ProfileCard />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetails;
