import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../common/SectionTitle';
import './LatestBlog.css'; 

const LatestBlog = () => {

  useEffect(() => {
    const articles = document.querySelectorAll('.single-article-special');
    articles.forEach(article => {
      article.addEventListener('click', () => {
        article.classList.toggle('hover');
      });
    });

    // Cleanup event listeners on component unmount
    return () => {
      articles.forEach(article => {
        article.removeEventListener('click', () => {
          article.classList.toggle('hover');
        });
      });
    };
  }, []);

  return (
    <>
      <section className='related-blog-list pt-130'>
        <div className='container'>
          {/* <div className='video-container'>
            <video controls>
              <source src="assets/fun/youngWildWorkingWithAli.webm" type="video/webm" />
              <source src="assets/fun/youngWildWorkingWithAli.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div> */}
          <div className='row align-items-center justify-content-between'>
            <div className='col-lg-4 col-md-12'>
              <SectionTitle
                subtitle=''
                title='Latest Blog Posts'
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 mb-4'>
              <div className='single-article rounded-custom'>
                <Link to='/1' className='article-img'>
                  <img
                    src='assets/img/ucf/embezzlers/gregory-welch-accounting-llc.gif'
                    className='img-scale'
                    alt='How Gregory Welch spends NSF + UCF money while complaining about his salary and vacation days'
                  />
                </Link>
                <div className='article-content p-4'>
                  <div className='article-category mb-4 d-block'>
                    <Link
                      to='/1'
                      className='d-inline-block text-dark badge bg-primary-soft'
                    >
                      Misappropriation
                    </Link>
                    <Link
                      to='/1'
                      className='d-inline-block text-dark badge bg-primary-soft'
                    >
                      Waste
                    </Link>
                    <Link
                      to='/1'
                      className='d-inline-block text-dark badge bg-primary-soft'
                    >
                      Fraud
                    </Link>
                    <Link
                      to='/1'
                      className='d-inline-block text-dark badge bg-primary-soft'
                    >
                      Whistleblowing
                    </Link>
                  </div>
                  <Link to='/1'>
                    <h2 className='h5 article-title limit-2-line-text'>
                      Gregory Welch declares Wes's ~2 months of work OVER $100,000!
                    </h2>
                  </Link>
                  <p className='limit-2-line-text'>
                    <a href="https://www.nsf.gov/awardsearch/showAward?AWD_ID=2235066&HistoricalAwards=false" target='_blank' rel="noreferrer">Where did the $2,232,872 / $4,192,490 USD go?</a>
                  </p>

                  <Link to='/1'>
                    <div className='d-flex align-items-center pt-4 avatar-info'>
                      <div className=''>
                        <h6 className='mb-0 avatar-name'>Gregory Welch</h6>
                        <span className='small fw-medium text-muted'>
                          August 2024
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 mb-4'>
              <div className='single-article rounded-custom'>
                <Link to='/1' className='article-img'>
                  <img
                    src='assets/img/vera/blog/1/1-register-1.gif'
                    alt='article'
                    className='img-fluid'
                  />
                </Link>
                <div className='article-content p-4'>
                  <div className='article-category mb-4 d-block'>
                    <Link
                      to='/1'
                      className='d-inline-block text-dark badge bg-primary-soft'
                    >
                      Engineering
                    </Link>
                  </div>
                  <Link to='/1'>
                    <h2 className='h5 article-title limit-2-line-text'>
                      VERA Engineering Update - Post 1
                    </h2>
                  </Link>
                  <p className='limit-2-line-text'>
                    Welcome! This is the first VERA Engineering Blog post. I have done my best to cater this post to the audience at large...
                  </p>

                  <Link to='/1'>
                    <div className='d-flex align-items-center pt-4 avatar-info'>
                      <div className=''>
                        <h6 className='mb-0 avatar-name'>Wes</h6>
                        <span className='small fw-medium text-muted'>
                          June 2024
                        </span>
                      </div>
                      <div className='px-2'>
                        <img src='/assets/img/team/wes-1.png' alt='Wes Lorenzini' className='avatar-img rounded-circle' />
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            {/* Add more blog posts here if needed */}
          </div>
        </div>
      </section>
    </>
  );
};

export default LatestBlog;